import './LoginPage.css';
import LoginLogo from '../assets/LoginLogo.png'
import LoginBarLogo from '../assets/LoginBarLogo.png'
import LoginSmallLogo from '../assets/LoginSmallLogo.png'
import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';



const LoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();


  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleLoginBTNClicked = async (e) => {
    e.preventDefault();
    const dataToSend = { Username: username, password: password };

    try {
      const response = await fetch('https://www.pimasens.com/api/token/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataToSend),
      });

      const result = await response.json();

      if (response.ok) {
        navigate('/SuperDuperConvolutedZenro', { state: { access:  result.access} });
      } else {
        setError(result.message || 'An error occurred');
      }
    } catch (err) {
      setError('An error occurred');
    }
  };

    const styles = {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'black',
        display: 'flex',
        justifyContent: 'center',
        alignItems:'center', // Center the image vertically
      };

      const lineStyles = {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: '43%',
        width: '1px',
        backgroundColor: 'white',
        background: 'rgba(255, 255, 255, 0.40)',
      };

      const inputStyles = {
        borderRadius: '10px',
        border: '1px solid #9CA5B6',
        background: 'rgba(231, 231, 231, 0.19)',
        display: 'inline-flex',
        padding: '10px 16px 10px 30px',
        justifyContent: 'flex-end',
        alignItems: 'center',
        gap: '171px',
      };


  return (
    <div style={styles}>
        <img src={LoginLogo} alt="LOGO" className="left-image" />
        <div style={lineStyles} />
        <input 
          type="text" 
          placeholder="UserName" 
          className='username-input' 
          value={username} 
          onChange={handleUsernameChange} 
        />
        <input 
          type="password" 
          placeholder="Password" 
          className='password-input' 
          value={password} 
          onChange={handlePasswordChange} 
        />
        
        <img src={LoginBarLogo} alt="LOGOBar" className="right-image-login" />
        <img src={LoginSmallLogo} alt="LOGOSmall" className="bottom-right-image" />
        <h1 className='welcome-text-login'>Welcome to PimaSens</h1>
        <button className="signin-button" onClick={handleLoginBTNClicked}>Sign In</button>

    </div>
  );
};

export default LoginPage;
