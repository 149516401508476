import './SignUpPage.css';
import LoginLogo from '../assets/LoginLogo.png'
import LoginBarLogo from '../assets/LoginBarLogo.png'
import LoginSmallLogo from '../assets/LoginSmallLogo.png'
import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';


const SignUpPage = () => {
    const [formData, setFormData] = useState({
        FirstName: '',
        LastName: '',
        Username: '',
        Email: '',
        phone: '',
        HealthID: '',
        password: '',
    });
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [id]: value,
        }));
      };
    
        

    const handleSignupBTNClicked = async (e) => {
        e.preventDefault();
        const dataToSend = { ...formData, UserType: 'user' };
          
    
        try {
          const response = await fetch('https://www.pimasens.com/api/CreateUser/', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataToSend),
          });
    
          const result = await response.json();
    
          if (response.ok) {
            navigate('/success', { state: { Username: result.Username } });
          } else {
            setError(result.message || 'An error occurred');
            console.error('Server error:', result.message || 'An error occurred');
          }
        } catch (err) {
          setError('An error occurred');
          console.error('Network error:', err);
        }
      };

    const styles = {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'black',
        display: 'flex',
        justifyContent: 'center',
        alignItems:'center', // Center the image vertically
      };

      const lineStyles = {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: '43%',
        width: '1px',
        backgroundColor: 'white',
        background: 'rgba(255, 255, 255, 0.40)',
      };

      const inputStyles = {
        borderRadius: '10px',
        border: '1px solid #9CA5B6',
        background: 'rgba(231, 231, 231, 0.19)',
        display: 'inline-flex',
        padding: '10px 16px 10px 30px',
        justifyContent: 'flex-end',
        alignItems: 'center',
        gap: '171px',
      };



      


  return (
    <div style={styles}>
        <img src={LoginLogo} alt="LOGO" className="left-image" />
        <div style={lineStyles} />
        <form className='SignUpPage-form'>
        <label className='SignUpPage-label' htmlFor="FirstName">First Name</label>
        <input type="text" id="FirstName" name="fname" value={formData.FirstName} onChange={handleChange} />
        <label className='SignUpPage-label' htmlFor="LastName">Last Name</label>
        <input type="text" id="LastName" name="lname" value={formData.LastName} onChange={handleChange} />
        <label className='SignUpPage-label' htmlFor="Username">Username</label>
        <input type="text" id="Username" name="lname" value={formData.Username} onChange={handleChange} />
        <label className='SignUpPage-label' htmlFor="Email">Email</label>
        <input type="email" id="Email" name="fname" value={formData.Email} onChange={handleChange} />
        <label className='SignUpPage-label' htmlFor="Phone">Phone</label>
        <input type="number" id="phone" name="lname" value={formData.Phone} onChange={handleChange} />
        <label className='SignUpPage-label' htmlFor="HealthID">HealthID</label>
        <input type="text" id="HealthID" name="fname" value={formData.HealthID} onChange={handleChange} />
        <label className='SignUpPage-label' htmlFor="Password">Password</label>
        <input type="password" id="password" name="lname" value={formData.Password} onChange={handleChange} />
      </form>
      <button type="submit" className="signup-button" onClick={handleSignupBTNClicked}>Sign up</button>
      {error && <div className="error">{error}</div>}
      <img src={LoginBarLogo} alt="LOGOBar" className="right-image" />
      <img src={LoginSmallLogo} alt="LOGOSmall" className="bottom-right-image" />
      <h1 className='welcome-text-signup'>Welcome to PimaSens</h1>
    </div>
  );
};

export default SignUpPage;
