import React from 'react';
import './RecommendationHistoryBox.css';


const RecommendationHistoryBox = ({  testData , selectedTest, onTestSelect }) => {
    const SeverityColor = ['#f54244', '#fce93d', '#47a857', '#fce93d', '#f54244']

    const handleItemClick = (test) => {
        if (selectedTest !== test) { // Only update if a different test is clicked
            onTestSelect(test);
        }
    };
    return (
        <div className="RecommendationHistoryBox">
            <h1 className='RecommendationHistoryBox-h1'>RESULTS</h1>
            <div className='RecommendationHistoryBox-line1'></div>
            {/* labels for test */}
            <h2 className='RecommendationHistoryBox-h2 Status'>Status</h2>
            <div className='RecommendationHistoryBox-icon Status' />
            <h2 className='RecommendationHistoryBox-h2 Analyte'>Analyte</h2>
            <div className='RecommendationHistoryBox-icon Analyte' />
            <h2 className='RecommendationHistoryBox-h2 Test'>Test</h2>
            <div className='RecommendationHistoryBox-icon Test' />
            <h2 className='RecommendationHistoryBox-h2 Date'>Date</h2>
            <div className='RecommendationHistoryBox-icon Date' />
            {/* Container of test */}
            <div className='RecommendationHistoryBox-line2'></div>
            <div className="RecommendationHistoryBox-item-container">
                <nav className="RecommendationHistoryBox-items">
                    <ul className='RecommendationHistoryBox-ul'>
                        {testData.slice().reverse().map(test => (
                            <li
                                key={test.TestID}
                                className={`test-item ${selectedTest === test ? 'active' : ''}`}
                                onClick={() => handleItemClick(test)}
                            >
                                <span className="RecommendationHistoryBox-status-box">
                                    <div className='RecommendationHistoryBox-status-icon' style={{ borderColor: SeverityColor[test.TestSeverity - 1] }} />
                                    <div className='RecommendationHistoryBox-line3' ></div>
                                    <h1 className='RecommendationHistoryBox-h3' style={{ color: SeverityColor[test.TestSeverity - 1] }}>{parseFloat(test.TestResult).toFixed(2)}</h1>
                                    <h1 className='RecommendationHistoryBox-h4' style={{ color: SeverityColor[test.TestSeverity - 1] }}>ng/mL</h1>
                                </span>
                                <span className="RecommendationHistoryBox-type-box">Cortisol</span>
                                <span className="RecommendationHistoryBox-item-name">Cortisol Test</span>
                                {/* <span className="RecommendationHistoryBox-item-name">{test.name}</span> */}
                                <span className="RecommendationHistoryBox-item-date">{new Date(test.Date).toLocaleDateString('en-US', {
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric'
                                })}</span>
                            </li>
                        ))}
                    </ul>
                </nav>
            </div>
        </div>
    );
};

export default RecommendationHistoryBox;

