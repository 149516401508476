import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './Zenro.css';
import axios from 'axios';
import OverviewPage from './OverviewPage';
import HistoryPage from './HistoryPage';
import RecommendationPage from './RecommendationsPage';
import AccountEditPage from './AccountEditPage';
import ZenroNavagatorBar from '../components/ZenroNavagatorBar';

const Zenro = () => {
    const [selectedMenuItem, setSelectedMenuItem] = useState('overview');
    const location = useLocation();
    const [User, setUser] = useState(null);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);
    const { access } = location.state || {};
    const navigate = useNavigate(); 
    //const access = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzMwOTE5NzUxLCJpYXQiOjE3MjgzMjc3NTEsImp0aSI6IjhiZjIwYjcyZTMyNDQyNDM4MDA2YmY0M2Y2NmMxYzI1IiwiVUlEIjo2fQ.nyBEE9KaQNZTukFxULVhyxVxHmazJP3HpFBpTSFyKOY'

    useEffect(() => {
        if (!access) {
            navigate('/SuperDuperConvolutedSignIN'); 
          } else {
        axios.get('https://www.pimasens.com/api/getUser/', {
            headers: {
                'Authorization': 'Bearer ' + access
            }
        })
            .then(response => {
                const data = response.data;

                if (data.error === "No tests found") {
                    setError('An error occurred');

                } else {
                    setUser(data);
                }
                setLoading(false); // Set loading to false after data is fetched
            })
            .catch(error => {
                setError('An error occurred');
                setLoading(false); // Set loading to false even if there's an error
            });
        }
    }, [access, navigate]);


    return (
        
        
        <div className="Zenro">
            {loading ? (
                <div>Loading...</div> // Show a loading indicator while data is being fetched
            ) : (
                <>
                    <div className="content">
                        {selectedMenuItem === 'overview' && <OverviewPage access={access} />}
                        {selectedMenuItem === 'history' && <HistoryPage access={access}/>}
                        {selectedMenuItem === 'recommendation' && <RecommendationPage access={access} />}
                        {selectedMenuItem === 'account' && <AccountEditPage access={access} userInfo={User}/>}
                    </div>
                    <ZenroNavagatorBar onMenuItemSelect={setSelectedMenuItem} userInfo={User} />
                </>
            )}
        </div>
    );

};

export default Zenro;