import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './Agrilo.css';
import AgriloNavagatorBar from '../components/AgriloNavagatorBar';
import agriloIconTop from '../assets/agriloIcons/AgriloTop.png';
import AgriloHistoryPage from './AgriloHistoryPage';

const Agrilo = () => {
    //const { access } = location.state || {};
    const access = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzI5MDU3NDg4LCJpYXQiOjE3MjY0NjU0ODgsImp0aSI6ImM4ZDg5YjA1MmU3YjRhODM4YjcxMTMxYzEwNGE4NzVmIiwiVUlEIjoyfQ.YhosNIX6Uq6W3sOhTwukOXD7mheyB4pvQqaMai0ULw8'



    return (


        <div className="Agrilo">
            <img src={agriloIconTop} alt="shot1" className="agriloIconTop" />
            <h1 className='Agrilo-h1'>Agrilo</h1>
            <AgriloNavagatorBar/>
            <AgriloHistoryPage/>
        </div>
    );

};



export default Agrilo;