import React, { useState, useEffect } from 'react';
import './RecommendationsPage.css';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import RecommendationHistoryBox from '../components/RecommendationHistoryBox';
import RecommendationSelectionBox from '../components/RecommendationSelectionBox';

const RecommendationPage = (access) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [selectedTest, setSelectedTest] = useState(null);
    // const { access } = location.state || {};

    const [error, setError] = useState('');
    const [tests, setTests] = useState([]);
    const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzI5MDU3NDg4LCJpYXQiOjE3MjY0NjU0ODgsImp0aSI6ImM4ZDg5YjA1MmU3YjRhODM4YjcxMTMxYzEwNGE4NzVmIiwiVUlEIjoyfQ.YhosNIX6Uq6W3sOhTwukOXD7mheyB4pvQqaMai0ULw8'
    useEffect(() => {
        axios.get('https://www.pimasens.com/api/getTest/', {
            headers: {
                'Authorization': 'Bearer ' + access.access
            }
        })
            .then(response => {
                const data = response.data;

                if (data.error === "No tests found") {
                    setError('An error occurred');
                } else {
                    const tests = data;
                    setTests(tests);
                }
            })
            .catch(error => {
                setError('An error occurred');
            });
    }, []);

    useEffect(() => {
        if (tests.length > 0) {
          setSelectedTest(tests[tests.length - 1]);
        }
      }, [tests]);

    return (
        <div className="RecommendationPage">
            <RecommendationHistoryBox testData={tests}
            selectedTest={selectedTest}
            onTestSelect={setSelectedTest}/>
            <RecommendationSelectionBox testData={selectedTest}/>
        </div>
    );


};

export default RecommendationPage;