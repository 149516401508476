import React, { useState } from 'react';
import './AgriloNavagatorBar.css';
import CalendarIcon from '../assets/agriloIcons/CalendarIcon.png';
import TasksIcon from '../assets/agriloIcons/TasksIcon.png';
import PinnedIcon from '../assets/agriloIcons/PinnedIcon.png';
import OverviewIcon from '../assets/agriloIcons/OverviewIcon.png';
import TestsIcon from '../assets/agriloIcons/TestsIcon.png';
import RecommendationsIcon from '../assets/agriloIcons/RecommendationsIcon.png';


const AgriloNavagatorBar = ({userInfo }) => {
    const [summaryExpend, setSummaryExpend] = useState(true);
    const [historyExpanded, setHistoryExpanded] = useState(true);
    const toggleMenu = (type) => {
        if (type == 'history') {
            setHistoryExpanded(!historyExpanded)
        } else {
            setSummaryExpend(!summaryExpend)
        }
    };



    return (
        <div className="AgriloNavagatorBar">
            <button className='AgriloNavagatorBar-summary-btn' onClick={() => toggleMenu('summary')}>Summary
                <i className="fa fa-caret-down"></i>
            </button>
            {summaryExpend && (
                <ul className="AgriloNavagatorBar-summary-menu">
                    {/* Your menu items go here */}
                    <li className='AgriloNavagatorBar-li'>
                        <img src={CalendarIcon} alt="shot1" className="CalendarIcon" />
                        Calendar
                    </li>
                    <li className='AgriloNavagatorBar-li'>
                        <img src={TasksIcon} alt="shot1" className="TasksIcon" />
                        Tasks
                    </li>
                    <li className='AgriloNavagatorBar-li'>
                        <img src={PinnedIcon} alt="shot1" className="PinnedIcon" />
                        Pinned
                    </li>
                </ul>
            )}
            <button className='AgriloNavagatorBar-history-btn' onClick={() => toggleMenu('history')}>History
                <i className="fa fa-caret-down"></i>
            </button>
            {historyExpanded && (
                <ul className="AgriloNavagatorBar-history-menu">
                    {/* Your menu items go here */}
                    <li className='AgriloNavagatorBar-li'>
                        <img src={OverviewIcon} alt="shot1" className="AgriloNavagatorBar-OverviewIcon" />
                        overview
                    </li>
                    <li className='AgriloNavagatorBar-li'>
                        <img src={TestsIcon} alt="shot1" className="AgriloNavagatorBar-TestsIcon" />
                        Tests
                    </li>
                    <li className='AgriloNavagatorBar-li'>
                        <img src={RecommendationsIcon} alt="shot1" className="AgriloNavagatorBar-RecommendationsIcon" />
                        Recommendations
                    </li>
                </ul>
            )}
            <h1 className='AgriloNavagatorBar-h1'>Account</h1>
        </div >
    );
};

export default AgriloNavagatorBar;
