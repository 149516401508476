import React, { useState, useEffect } from 'react';
import './HistoryPage.css';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import HistoryBox from '../components/HistoryBox';
import SelectHistoryBox from '../components/SelectHistoryBox';



const HistoryPage = (access) => {
    const [selectedTest, setSelectedTest] = useState(null);
    // const { access } = location.state || {};

    const [error, setError] = useState('');
    const [tests, setTests] = useState([]);
    const [loading, setLoading] = useState(true);
    const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzI5MDU3NDg4LCJpYXQiOjE3MjY0NjU0ODgsImp0aSI6ImM4ZDg5YjA1MmU3YjRhODM4YjcxMTMxYzEwNGE4NzVmIiwiVUlEIjoyfQ.YhosNIX6Uq6W3sOhTwukOXD7mheyB4pvQqaMai0ULw8'
    useEffect(() => {
        axios.get('https://www.pimasens.com/api/getTest/', {
            headers: {
                'Authorization': 'Bearer ' + access.access
            }
        })
            .then(response => {
                const data = response.data;

                if (data.error === "No tests found") {
                    setError('An error occurred');
                } else {
                    console.log(data)
                    setTests(data);
                }
                setLoading(false);
            })
            .catch(error => {
                setError('An error occurred');
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        if (tests.length > 0) {
          setSelectedTest(tests[tests.length - 1]);
        }
      }, [tests]);

    return (
        <div className="HistoryPage">
            {loading ? (
                <div>Loading...</div> // Show a loading indicator while data is being fetched
            ) : (
                <>
            <HistoryBox testData={tests}
            selectedTest={selectedTest}
            access={access.access}
            onTestSelect={setSelectedTest}/>
            <SelectHistoryBox testData={selectedTest} access={access.access}/>
                </>
            )}
        </div>
    );


};

export default HistoryPage;